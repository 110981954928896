//
//
//
//
//
//

export default {
  name: 'FeaturedInItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageName () {
      const isDark = this.$store.state.ui.isDarkThemeEnabled
      const imageName = `${this.item.name}${isDark ? '_dark' : ''}.png`
      return require(`@/assets/img/featured/${imageName}`)
    }
  }
}
