//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SeoCategoryTab',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    skills () {
      return this.$store.getters['util/getSkillsByCategory'](this.category.id)
    },
    half () {
      return Math.ceil(this.skills.length / 2)
    },
    firstHalf () {
      return this.skills.slice(0, this.half)
    },
    secondHalf () {
      return this.skills.slice(this.half)
    }
  }
}
