//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FindTalent from '@/components/landing/FindTalent'
import BrowseJobs from '@/components/landing/BrowseJobs'
import BrowseFreelancers from '@/components/landing/BrowseFreelancers'
import Testimonials from '@/components/landing/Testimonials'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import PartnersSlider from '@/components/landing/PartnersSlider'
import FeaturedIn from '@/components/landing/FeaturedIn'
import WhyUs from '@/components/landing/WhyUs'
import FindJob from '@/components/landing/FindJob'
import AgentsSignup from '@/components/landing/AgentsSignup'
import SeoCategories from '@/components/landing/SeoCategories'
import Pageview from '@/mixins/Pageview'

export default {
  name: 'HomePage',
  components: {
    SeoCategories,
    AgentsSignup,
    FindJob,
    WhyUs,
    FeaturedIn,
    PartnersSlider,
    Testimonials,
    BrowseFreelancers,
    BrowseJobs,
    FindTalent
  },
  mixins: [Pageview],
  layout: 'blank',
  auth: false,
  middleware: ['redirectLanding'],
  data () {
    return {
      texts: {}
    }
  },
  async fetch () {
    // fetch landing texts
    if (!this.texts[this.locale]) {
      const leadRes = await this.$axios.get(`/pages/landing-lead-${this.locale}`)
      const missionRes = await this.$axios.get(`/pages/landing-mission-${this.locale}`)

      const t = {
        [this.locale]: {
          lead: leadRes.data.data.text,
          mission: missionRes.data.data.text
        }
      }

      this.texts = {
        ...this.texts,
        ...t
      }
    }
  },
  computed: {
    activeRole () {
      return this.$store.getters['user/getActiveRoleType']?.name
    },

    locale () {
      return this.$store.state.i18n.locale
    },

    leadText () {
      return this.texts[this.locale]?.lead
    },

    missionText () {
      return this.texts[this.locale]?.mission
    }
  }
}
