//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WhyUsItem',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    isDarkMode () {
      return this.$store.state.ui.isDarkThemeEnabled
    },
    iconSrc () {
      const imageName = `${this.icon}${this.isDarkMode ? '_dark' : ''}.svg`
      return require(`@/assets/img/whyUs/${imageName}`)
    }
  }
}
