//
//
//
//

export default {
  name: 'TaskLocation',
  props: {
    location: {
      type: String,
      required: true
    }
  }
}
