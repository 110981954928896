//
//
//
//
//
//
//
//
//
//
//
//

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'TaskPrice',
  props: {
    task: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    type () {
      return this.task.priceType ? this.$t(`tasks.form.${this.task.priceType}`) : ''
    },

    currency () {
      const currenciesMap = this.$store.getters['util/getCurrenciesMap']
      return currenciesMap[this.task.currencyId]
    },

    priceWithCurrency () {
      return this.task.price ? `${this.currency?.symbolPosition === 'pre' ? this.currency.symbol : ''}${this.task.price}${this.currency?.symbolPosition === 'post' ? this.currency.symbol : ''}` : ''
    }
  }
}
