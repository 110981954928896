//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AvatarDisplay from '@/components/ui/AvatarDisplay'

export default {
  name: 'FreelancerItem',
  components: {
    AvatarDisplay
  },
  props: {
    freelancer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      avatarOptions: {
        resize: {
          width: 148,
          height: 172
        }
      }
    }
  },
  computed: {
    freelancerData () {
      return this.freelancer._source
    },
    avatar () {
      return this.freelancerData.avatar
    },
    name () {
      return this.freelancerData.firstName + ' ' + this.freelancerData.lastName
    }
  }
}
