//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WhyUsItem from '@/components/landing/WhyUsItem'

export default {
  name: 'WhyUs',
  components: { WhyUsItem }
}
