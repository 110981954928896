//
//
//
//
//
//

export default {
  name: 'PartnersItem',
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageName () {
      const isDark = this.$store.state.ui.isDarkThemeEnabled
      const imageName = `${this.partner.name}${isDark ? '_dark' : ''}.png`
      return require(`@/assets/img/partners/${imageName}`)
    }
  }
}
