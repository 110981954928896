//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import FeaturedInItem from '@/components/landing/FeaturedInItem'

export default {
  name: 'FeaturedIn',
  components: {
    FeaturedInItem,
    VueSlickCarousel
  },
  data () {
    return {
      /**
       * For each item there should be 2 .png images in assets/img/featured folder
       * For dark logo image should have <name>_dark.png image
       * For light logo image should be <name>.png
       */
      items: [
        {
          name: 'forbes',
          url: 'https://www.forbes.com/sites/davidpetersson/2018/10/27/the-problems-with-freelancing-and-how-blockchain-can-help/?sh=75c1f9747b8d'
        },
        {
          name: 'venturebeat',
          url: 'https://venturebeat.com/2017/12/01/how-to-protect-ico-investors-auto-refunds-if-milestones-arent-met/'
        },
        {
          name: 'cnbc',
          url: 'https://www.youtube.com/watch?v=g5iWqdtsx-4&feature=youtu.be'
        },
        {
          name: 'cointelegraph',
          url: 'https://cointelegraph.com/explained/prediction-markets-explained'
        }
      ],
      settings: {
        arrows: false,
        autoplay: true,
        autoplayHoverPause: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  }
}
