//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import TestimonialItem from '@/components/landing/TestimonialItem'

export default {
  name: 'Testimonials',
  components: {
    TestimonialItem,
    VueSlickCarousel
  },
  data () {
    return {
      testimonials: []
    }
  },
  async fetch () {
    await this.getTestimonials()
  },
  methods: {
    async getTestimonials () {
      const res = await this.$axios.get('/testimonials')
      this.testimonials = res.data.data
    }
  }
}
