//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import LoadingOverlay from '@/components/ui/LoadingOverlay'
import BrowseFreelancersTab from '@/components/landing/BrowseFreelancersTab'
import FreelancerItem from '@/components/landing/FreelancerItem'

export default {
  name: 'BrowseFreelancers',
  components: {
    FreelancerItem,
    BrowseFreelancersTab,
    LoadingOverlay,
    VueSlickCarousel
  },
  data () {
    return {
      loading: false,
      freelancers: {
        featured: []
      }
    }
  },
  async fetch () {
    await this.getFreelancers('featured')
  },
  computed: {
    categories () {
      return this.$store.getters['util/getCategoriesByName']
    },
    hasFeatured () {
      return this.freelancers.featured.length
    }
  },
  methods: {
    async getFreelancers (category) {
      if (!this.freelancers[category] || !this.freelancers[category].length) {
        try {
          this.loading = true
          const params = {
            perPage: 12,
            featured: true
          }

          if (category !== 'featured') {
            params.category = category
          }

          const res = await this.$axios.get('/freelancers', { params })

          this.freelancers = Object.assign({}, this.freelancers, { [category]: res.data.data.hits })
          this.loading = false
        } catch (err) {
          this.loading = false
        }
      }
    }
  }
}
