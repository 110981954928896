//
//
//
//

export default {
  name: 'TaskType',
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
