//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AvatarDisplay from '@/components/ui/AvatarDisplay'
import TaskType from '@/components/tasks/TaskType'
import TaskLocation from '@/components/tasks/TaskLocation'
import TaskPrice from '@/components/tasks/TaskPrice'
import TaskTags from '@/components/tasks/TaskTags'

export default {
  name: 'BrowseJobsJobItem',
  components: {
    TaskTags,
    TaskPrice,
    TaskLocation,
    TaskType,
    AvatarDisplay
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      avatarOptions: {
        resize: {
          width: 120,
          height: 120
        }
      }
    }
  },
  computed: {
    taskData () {
      return this.task._source
    },
    clientLink () {
      return this.localePath(`/clients/${this.taskData.postedBySlug}/${this.taskData.postedById}`)
    },
    avatar () {
      return {
        fileName: this.taskData.postedByAvatar
      }
    },
    taskUrl () {
      return this.localePath(`/tasks/${this.taskData.slug}/${this.task._id}`)
    }
  }
}
