//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BrowseJobsTab from '@/components/landing/BrowseJobsTab'
import LoadingOverlay from '@/components/ui/LoadingOverlay'

export default {
  name: 'BrowseJobs',
  components: {
    LoadingOverlay,
    BrowseJobsTab
  },
  data () {
    return {
      tasks: {},
      loading: false,
      lastTask: null
    }
  },
  async fetch () {
    await this.getTasks('featured')
    await this.getLastTask()
  },
  computed: {
    categories () {
      return this.$store.getters['util/getCategoriesByName']
    },
    when () {
      return this.lastTask ? this.$moment(this.lastTask._source.timePosted).fromNow(true) : ''
    }
  },
  methods: {
    async getTasks (category) {
      if (!this.tasks[category]) {
        try {
          this.loading = true
          const params = {
            perPage: 10
          }

          if (category === 'featured') {
            params.featured = true
          } else {
            params.category = category
          }

          const res = await this.$axios.get('/tasks', { params })

          this.tasks = Object.assign({}, this.tasks, { [category]: res.data.data.hits })
          this.loading = false
        } catch (err) {
          this.loading = false
        }
      }
    },

    async getLastTask () {
      if (!this.lastTask) {
        try {
          const params = {
            perPage: 1
          }
          const res = await this.$axios.get('/tasks', { params })
          this.lastTask = res.data.data.hits[0]
        } catch (err) {
          //
        }
      }
    }
  }
}
