//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import PartnersItem from '@/components/landing/PartnersItem'

export default {
  name: 'PartnersSlider',
  components: {
    PartnersItem,
    VueSlickCarousel
  },
  data () {
    return {
      /**
       * For each partner there should be 2 .png images in assets/img/partners folder
       * For dark logo image should have <name>_dark.png image
       * For light logo image should be <name>.png
       */
      partners: [
        {
          name: 'wespa',
          url: 'https://wespa-spaces.hr/'
        },
        {
          name: 'tezos',
          url: 'https://tezos.com/'
        },
        {
          name: 'clemta',
          url: 'https://clemta.com/'
        },
        {
          name: 'conet',
          url: 'https://www.conet.de/EN/CONET'
        },
        {
          name: 'firo',
          url: 'https://firo.org/'
        }
      ],
      settings: {
        arrows: false,
        autoplay: true,
        autoplayHoverPause: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  }
}
