import { render, staticRenderFns } from "./BrowseFreelancers.vue?vue&type=template&id=4c82319a&scoped=true&"
import script from "./BrowseFreelancers.vue?vue&type=script&lang=js&"
export * from "./BrowseFreelancers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c82319a",
  null
  
)

export default component.exports