//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AvatarDisplay from '@/components/ui/AvatarDisplay'

export default {
  name: 'TestimonialItem',
  components: { AvatarDisplay },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      avatarOptions: {
        resize: {
          width: 90,
          height: 90
        }
      }
    }
  }
}
