//
//
//
//
//
//
//
//
//

import TaskTag from '@/components/tasks/TaskTag'

export default {
  name: 'TaskTags',
  components: { TaskTag },
  props: {
    tagIds: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    tags () {
      const skills = this.$store.getters['util/getAllSkillsMappings']
      return this.tagIds.map(id => skills[id])
    }
  }
}
