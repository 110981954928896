//
//
//
//
//
//
//
//
//
//

import FreelancerItem from '@/components/landing/FreelancerItem'

export default {
  name: 'BrowseFreelancersTab',
  components: { FreelancerItem },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    category: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    freelancers: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    openTab () {
      this.$emit('change', this.category?.id || 'featured')
    }
  }
}
