//
//
//
//
//
//

export default {
  name: 'TaskTag',
  props: {
    tag: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
