export default {
  mounted () {
    if (this.$gtag) {
      setTimeout(() => {
        const info = {
          page_path: location.pathname,
          page_title: document.title,
          page_location: location.href
        }
        this.$gtag.pageview(info)
      }, 1000)
    }
  }
}
