//
//
//
//
//
//
//
//
//
//

import BrowseJobsJobItem from '@/components/landing/BrowseJobsJobItem'
export default {
  name: 'BrowseJobsTab',
  components: { BrowseJobsJobItem },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    category: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    tasks: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    openTab () {
      this.$emit('change', this.category?.id || 'featured')
    }
  }
}
