//
//
//
//
//
//
//
//
//
//

import SeoCategoryTab from '@/components/landing/SeoCategoryTab'
export default {
  name: 'SeoCategories',
  components: { SeoCategoryTab },
  computed: {
    categories () {
      return this.$store.getters['util/getCategoriesByOrder']
    }
  }
}
